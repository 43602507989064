import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import MaskText from "../components/MaskText";
import Toast from "../components/Toast";
import {useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
// import service_icon from "../car-service.png";
import customer_icon from "../profile.png";
import { dataProvider } from "../util";
import AlertDialog from "../components/AlertDialog";

// import * as Scroll from 'react-scroll';
import { scroller } from "react-scroll";

// var scroll = animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function AddCustomer() {
    let history = useHistory();
  const classes = useStyles();
  // const [service, setService] = React.useState({});
  const [customer, setCustomer] = React.useState({});

  // const [serviceList, setServiceList] = React.useState([]);
  // const [agree, setAgree] = React.useState(false);
  const form = useRef();

  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -90,
    });
  };
  const handleDialogAgree = () => {
    //  window.alert('agree');
    // console.log('agree')
    setIsOpen(false);
    // setAgree(true);

    if (!customer["Registration Number"] || customer["Registration Number"].length < 2) {
      doToast("Invalid Registration Number");
      scrolly("Registration Number");
      return false;
    }

    // if(!customer) return false;

    if (!customer["VIN"] || customer["VIN"].length < 1) {
      doToast("Invalid VIN");
      scrolly("VIN");
      return false;
    }

    if (!customer["First name"] || customer["First name"].length < 1) {
      doToast("Name should be atleast 1 character");
      scrolly("First name");
      return false;
    }
    if (!customer["Last name"] || customer["Last name"].length < 1) {
      doToast("Name should be atleast 1 character");
      scrolly("Last name");
      return false;
    }

    if (!customer["Contact number"] || customer["Contact number"].length < 10) {
      doToast(
        "Contact number should be atleast (10) digits. e.g 8761234567 or 18765001234"
      );
      scrolly("Contact number");
      return false;
    }
    if (
      typeof customer["Email"] !== "undefined" &&
      customer["Email"] !== "none"
    ) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(customer["Email"])) {
        doToast("Please enter valid email address.");
        return false;
      }
    }

    //postCustomer(customer)
    dataProvider('post', '/customer', customer)
      .then((res) => {
        // console.log(res)
        setIsOpen(false);
        setToastMessage(res.data.message);
        setIsToastOpen(true);

        if(res.data.value === true){
            setTimeout(() => {
          reset();
          history.push('/make-request?vin='+customer["VIN"])
        }, 2000);
        }
        
      })
      .catch((error) => {
        setIsOpen(false);
        setToastMessage(error.response.data);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  };


  const reset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setCustomer({});
  };

  const doToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };
  const handleDialogCancel = () => {
    // window.alert('cancel');
    setIsOpen(false);
  };
  // -----------------------------------------------------

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  // const handleChange = (event) => {
  //   // if(event.target.checked === true) {
  //   setService({ ...service, [event.target.name]: event.target.checked });
  //   // }
  // };

  const handleCustomerChange = (event) => {
    setCustomer({ ...customer, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    scrolly("pageTop");
    // async function fetchServices() {
      
    //   if (sessionStorage.getItem("services")) {
    //     let session_data = JSON.parse(sessionStorage.getItem("services"));
    //     setServiceList(session_data.data);
    //     scrolly("pageTop");
    //   } else {
    //     try {
    //       let live_data = await getServiceList();
    //       if(typeof(live_data) === 'undefined' || live_data === "Network Error") throw new Error('Please restart application. Internet Error.'); 
    //       console.log(1);
    //       sessionStorage.setItem("services", JSON.stringify(live_data));
    //       setServiceList(live_data.data);
    //       scrolly("pageTop");
    //     } catch (error) {
    //       setToastMessage(error.message);
    //       setIsToastOpen(true);
    //     }
    //   }
    // }
    // fetchServices();
  }, []);

  return (
    <div className={classes.root} id="pageTop">
      <form className={classes.formRoot} autoComplete="off" ref={form}>
        <Grid container spacing={3} style={{ paddingTop: 30 }}>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <Typography gutterBottom variant="h2" style={{fontSize:'2em'}}>
                  Welcome to <b><span style={{color:"#f9034e", WebkitTextStroke:"1px #0c3b69"}}>Scott's</span> <span style={{padding:"3px", fontSize:"0.7em", color:"white", WebkitTextStroke:"1px #0c3b69", background:"#222"}}>Auto</span></b> Service Center
                  </Typography>    */}
                  <Typography
                    gutterBottom
                    variant="h2"
                    style={{ fontSize: "2em" }}
                  >
                   <b>Add</b> Customer
                  </Typography>
                </Grid>
                {/* <Grid item>1
                  <Typography gutterBottom variant="h6">
                    $4.50
                  </Typography>
                </Grid> */}
              </Grid>

              <Divider
                style={{
                  marginTop: 15,
                  background: "#f1f1f1",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <ServiceIcon fontSize="large" className={classes.icon}/> */}
                  <img alt="customer" src={customer_icon} width={60} />
                  <Typography gutterBottom variant="h5">
                    Customer details
                  </Typography>
                </Grid>
                {/* <Grid item>
            <Typography gutterBottom variant="h6">
              $4.50
            </Typography>
          </Grid> */}
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Please provide us with a few details, so we can serve your
                better.
              </Typography>
              {/* <Divider style={{ marginTop: 15 }} /> */}
            </div>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="VIN"
              name="VIN"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              // helperText="Incorrect entry."
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <small style={{ color: "#ccc" }}>Vehicle ID Number</small>
          </Grid>
          <Grid item xs={6} md={3}>
            {/* <Paper className={classes.paper}>xs=12</Paper> */}
            <TextField
              label="Registration Number"
              variant="outlined"
              className={classes.flow}
              name="Registration Number"
              onChange={handleCustomerChange}
              required
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <small style={{ color: "#ccc" }}>Licence plate number e.g 3456AB</small>
          </Grid>
         
          <Grid item xs={12} md={6}>
            <TextField
              label="First name"
              name="First name"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Last name"
              name="Last name"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Contact number"
              name="Contact number"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              // InputProps={{
              //   inputComponent: MaskText,
              // }}
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 11);
              }}
              inputProps={{ maxLength: 11 }}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              label="Email"
              name="Email"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              type="email"
              // required
              autoComplete="off"
            />
          </Grid>
  
        </Grid>

        <Grid container spacing={3}>
           <Grid item xs={12} style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              size="large"
              onClick={reset}
              style={{ marginRight: 20 }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleDialogOpen}
            //   type="submit"
            >
              Save
            </Button>

            <AlertDialog
              isOpen={isOpen}
              handleClose={handleDialogClose}
              handleAgree={handleDialogAgree}
              handleCancel={handleDialogCancel}
              title="Customer Confirmation"
              subtitle="By clicking AGREE, means the information listed below is correct."
              children={<div>
                  <div><b>Name:</b> {(customer["First name"]?customer["First name"]:'')+" "+(customer["Last name"]?customer["Last name"]:'')}</div>
                  <div><b>VIN:</b> {customer["VIN"]?customer["VIN"]:''}</div>
                  <div><b>Registration:</b> {customer["Registration Number"]?customer["Registration Number"]:''}</div>
                  <div><b>Contact:</b> {customer["Contact number"]?customer["Contact number"]:''}</div>
                  <div><b>Email:</b> {customer["Email"]?customer["Email"]:''}</div>
                  </div>}        />
          </Grid>
        </Grid>
      </form>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
