import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
var axios = require("axios");



export const config = {
  // domain: "http://localhost:1980"
  // domain: "https://api2.scottsautoja.com/api/scotts/",
  domain: "https://do-api.scottsautoja.com/",
  name: "Scott's Auto BIMMER LIFESTYLE AUTOCARE",
  userInvoice: "https://scottsautoja.com/customer/invoice.php?id="
};

if (process.env.NODE_ENV === "development") {
  config.domain = "http://localhost:1980/";
} else {
  config.domain = "https://do-api.scottsautoja.com/";
}
axios.defaults.timeout = 6000;
const axios_198 = axios.create({
  baseURL: config.domain,
  // baseURL: "http://192.168.50.124:1980",
  //   baseURL: "http://192.168.100.67:1980",
  // baseURL: "http://192.168.0.12:1980",
  // baseURL: "http://192.168.0.21:1980",
  //   baseURL: "http://192.168.0.23:1980",
  //   baseURL: "http://localhost:1980",
  // baseURL: "https://api.scottsautoja.com/api/scotts/",
  timeout: 90000,
  // headers: {'X-Custom-Header': 'foobar'}
});

export function getToken() {
  if (checkAuth()) {
    var token = localStorage.getItem("token");
    token = JSON.parse(token);
    // var decoded = jwt_decode(token);
    // console.log(decoded);

    return token;
  } else {
    return "";
  }
}

export function checkAuth() {
  var token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  token = JSON.parse(token);
  var jwt = jwt_decode(token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return true;
  } else {
    localStorage.removeItem("token");
    return false;
  }
}

export function getProfile() {
  var token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  token = JSON.parse(token);
  var jwt = jwt_decode(token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return jwt;
  } else {
    localStorage.removeItem("token");
    return false;
  }
}

export async function getServiceList() {
  // console.log(getToken());
  try {
    const response = await axios_198.get(
      "serviceList",
      // {},
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // throw new Error(error);
    return error.message;
  }
}

export async function getBookings() {
  // console.log(getToken());
  try {
    const response = await axios_198.get(
      "bookings",
      // {},
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // throw new Error(error);
    return Promise.reject(error);
    // return error.message;
  }
}

export async function postService(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.post(
      "service",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function postCustomer(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.post(
      "customer",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function putService(data) {
  console.log(data);
  if (data.status === "complete" || data.status === "canceled") {
    // window.alert("Cannot update final requests");
    return Promise.reject({ message: "Cannot update final requests" });
  }
  // return false;
  try {
    const response = await axios_198.put(
      "service",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function putServiceItem(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.put(
      "serviceItem",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function doAPI(path, data) {
  // console.log(getToken());
  try {
    const response = await axios_198.put(
      path,
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function getPDF(u) {
  axios_198({
    method: "GET",
    url: u,
    responseType: "blob",
    headers: { Authorization: "Bearer " + getToken() },
  })
    .then((response) => {
      //Create a Blob from the PDF Stream
      // const file = new Blob([response.data]);
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // window.open(fileURL);
      window.location.href = fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getPDF_(u) {
  axios_198({
    method: "GET",
    url: u,
    responseType: "blob",
    headers: { Authorization: "Bearer " + getToken() },
  })
    .then((response) => {
      //Create a Blob from the PDF Stream
      // const file = new Blob([response.data]);
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // window.open(fileURL);
      window.open(fileURL, '_blank').focus();
      // window.location.href = fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function whatsappInvoice(u, id) {
  // window.open(`mailto:${email}?Content-Type=text/html&amp;subject=Invoice%20-%20${config.name}&body=Your invoice is ready, click %3Ca%20href%3D%27${u}%27%3Ehere%3C%2Fa%3E or the link below to view your invoice  %3Ca%20href%3D%27${u}%27%3E${u}%3C%2Fa%3E %0A%0AThank you.`, '_blank').focus(); 
  
  window.open(`https://wa.me/${id}?text=Hello, your invoice is now ready, click the link to view your invoice 👉 ${u} Thank you.`, '_blank').focus();
}

export function emailInvoice(u, id) {
  window.open(`mailto:${id}?subject=Invoice%20-%20${config.name}&body=Hello, your invoice is now ready, click the link to view your invoice 👉 ${u} Thank you.`, '_blank').focus(); 
  
  // window.open(`https://wa.me/${id}?text=Hello, your invoice is now ready, click the link to view your invoice 👉 ${u} Thank you.`, '_blank').focus();
}

export async function dataProvider(method, url, data) {
  try {
    const response = await axios_198({
      method: method,
      url: url,
      data: { data },
      headers: { Authorization: "Bearer " + getToken() },
    });
    // console.log(response, "provider");
    return response;
  } catch (error) {

    // console.log(error.response.statusText,"prop")
    // console.log(error.response,"prop")
    if (error.response) {
      throw new Error(error.response?.statusText);
      // throw new Error(error.response?.data.message);
    } else {
      throw new Error("Request timeout.");
    }
  }
}

export async function dataProvider2(method, url, data) {
  try {
    const response = await axios_198({
      method: method,
      url: url,
      data: data,
      headers: { Authorization: "Bearer " + getToken() },
    });
    // console.log(response, "provider");
    return response;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error("Request timeout.");
    }
  }
}

export async function putCustomer(data) {
  // console.log(getToken());
  try {
    const response = await axios_198.put(
      "customer",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response,"resp");
    return response;
  } catch (error) {
    // console.log(error.message, "reter")
    // throw new Error(error.message);
    return Promise.reject(error);
  }
}

export async function getCustomers() {
  // console.log(getToken());
  try {
    const response = await axios_198.get(
      "customers",
      // {},
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    throw new Error(error);
    // return error.message;
  }
}

export async function getCustomer(data) {
  // console.log(getToken());
  // console.log()
  try {
    const response = await axios_198.post(
      "customer-fetch",
      { data },
      { headers: { Authorization: "Bearer " + getToken() } }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // console.log(error.message,"util")
    throw new Error(error);
    // return error;
  }
}

export async function doLogin(data) {
  try {
    const response = await axios_198.post("login", data);
    // console.log(response);
    return response;
  } catch (error) {
    // return error.message;
    throw new Error(error);
  }
}
export async function doLogout() {
  localStorage.removeItem("token");
  // if (caches) {
  //   // Service worker cache should be cleared with caches.delete()
  //   caches.keys().then((names) => {
  //     for (const name of names) {
  //       caches.delete(name);
  //     }
  //   });
  // }
  window.location = "/login";
}

export async function getBuild() {
  try {
    const b = await fetch("metadata.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // Work with JSON data here
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
        // Do something for an error here
      });
    return b;
  } catch (error) {
    console.log(error);
  }
}

export function getSettings() {
  return JSON.parse(sessionStorage.getItem("settings"));
}

export function isIOSDevice(){
  return  /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  // console.log(navigator.platform)
  // return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}


export async function doRequest(method, url, data) {
  try {
    const response = await axios_198({
      method: method,
      url: url,
      data:data,
      headers: { Authorization: "Bearer " + getToken() },
    });
    // console.log(response, "provider");
    return response;
  } catch (error) {
    // console.log(error, "doReq")
    // console.log(error.statusText, "doReq")
    // console.log(error.request, "reqq")
    const {data} = error.response;
    // console.log(data.message, "ress")

    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      // title: 'Your work has been saved',
      text: data.message,
      showConfirmButton: false,
      timer: 2100
    });
    // return false;
  }
}