import React, { useState} from "react";
import { Button, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {doLogin} from "../util";
import Toast from "../components/Toast"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function Login() {
  const classes = useStyles();
  const [login, setLogin] = useState({});

    //Toast ----------------------------------------
    const [isToastOpen, setIsToastOpen] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState("");
    const handleToastClose = () => {
        setIsToastOpen(false);
    };
  
    // -----------------------------------------------------

    //clear cache
    React.useEffect(()=> {
    //  caches.keys().then(keys => { keys.forEach(key => console.log(key, "cache")) })
      // if (caches) {
        // Service worker cache should be cleared with caches.delete()
      //   caches.keys().then((names) => {
      //     for (const name of names) {
      //       // console.log(name)
      //       caches.delete(name);
      //     }
      //   });
      // } 
      // delete browser cache and hard reload
      // window.location.reload(true);
      // console.log('Cache cleared');
    },[])

  const handleChange = (e) => {
    setLogin({...login, [e.target.name]:e.target.value})
  }
  const handleSubmit = (e) =>{
    e.preventDefault();
    if(!login.username && login.username?.length < 2) { window.alert("Enter valid username");return false;}
    if(!login.password && login.password?.length < 2) { window.alert("Enter valid username");return false;}

    doLogin(login).then(res => {
        if(res.data?.value === true){
            setToastMessage(res.data.message);
            setIsToastOpen(true);

            localStorage.setItem('token', JSON.stringify(res.data.token));
            window.location = "/";
            // setLogin({});
        } else {
            setToastMessage(res.data.message);
            setIsToastOpen(true);
        }

    })
    .catch(err => {
        setToastMessage(err.message);
        setIsToastOpen(true);
    })
    // console.log(login);
    //   Object.entries(login).forEach((v)=>{
    //       console.log(v[1]);
    //   })
  }
  return (
    <div className={classes.root}>
      <form  className={classes.formRoot} onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{paddingTop: 90 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Username"
              name="username"
              variant="outlined"
              className={classes.flow}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              className={classes.flow}
              onChange={handleChange}
              required
              autoComplete="new-password"
              inputProps={{   
                autoComplete: 'off',     
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" type="submit">Login</Button>
          </Grid>
        </Grid>
      </form>
      <Toast isOpen={isToastOpen} handleClose={handleToastClose}  message={toastMessage} />
    </div>
  );
}
