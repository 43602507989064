import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// import MaskText from "../components/MaskText";
import Toast from "../components/Toast";
import { useHistory, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  LinearProgress
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import service_icon from "../car-service.png";
import icon from "../invoice.png";
import {
  dataProvider,
  getPDF_,
  config,
} from "../util";
import AlertDialog from "../components/AlertDialog";

// import * as Scroll from 'react-scroll';
import { scroller } from "react-scroll";
// import WhatsAppIcon from '@material-ui/icons-material/WhatsApp';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DataTable from "../components/DataTable";
import Swal from "sweetalert2";
import {
  CreditCard,
  Description,
  Money,
  MoneyOff,
  Loyalty,
} from "@material-ui/icons";
// import transitions from "@material-ui/core/styles/transitions";
// var scroll = animateScroll;
require("dotenv").config();
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
  hint: {
    color: "#aaa",
  },
}));

export default function ViewInvoice() {
  let history = useHistory();
  const classes = useStyles();
  const [formData, setFormData] = React.useState({});
  const [totals, setTotals] = React.useState({
    product: 0,
    labour: 0,
    total: 0,
  });
  const [payment, setPayment] = React.useState();
  const REDEEM_POINT = process.env.REACT_APP_REDEEM_POINT || 50;

  // const [state, dispatch] = useReducer(reducer, {})
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  // const [preInvoice, setPreInvoice] = React.useState({}); //saved invoice data
  const [serviceRequest, setServiceRequest] = React.useState({});
  const [paid, setPaid] = React.useState(false);
  const [products, setProducts] = React.useState([]); //List OF Company Products
  const [invoiceData, setInvoiceData] = React.useState("");
  const [productInvoice, setProductInvoice] = React.useState([]); //Product added to this invoice
  const [option, setOption] = useState({ title: "Products", id: "active" });
  const queryID = useQuery().get("id");
  // const [customer, setCustomer] = useState({});
  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {
    setIsOpen(true);
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const paymentDialog = async (trans) => {
    const { value: payment } = await Swal.fire({
      title: "Receive Payment",
      input: "number",
      html: "<div style='text-align:left;'>Amount to Pay</div>",
      inputValue: totals.total,
      inputAttributes: {
        max: totals.total,
      },
      showCancelButton: true,
      confirmButtonText: "Save Payment",
      inputValidator: (value) => {
        if (!value) {
          return "You need to have a value";
        }
      },
    });

    if (payment) {
      console.log(payment);
      makePayment({
        ...trans,
        invoice_id: queryID,
        amount: payment,
        vin: serviceRequest.vin,
      });
    }
  };

  /**
   * APply Points
   * @param {object} trans
   */
  const applyPoints = async (trans) => {
    var customerProfile = null;

    try {
      const mydata = await dataProvider(
        "get",
        "/customerProfile/" + serviceRequest.vin,
        null
      );
      customerProfile = mydata.data.data;
    } catch (error) {
      console.log(error);
      // reject('Request failed')
      return false;
    }

    const { value: disc } = await Swal.fire({
      title: "Enter points to apply",
      input: "number",
      html: `<div><small>Your current points: <b>${customerProfile.current_points}</b></small></div>`,
      footer: `<div><small id="savingsStatus">-</small></div><div id="calc_discount"></div>`,

      inputValue: customerProfile.current_points,
      // value: customerProfile.current_points,

      didOpen: (dom) => {
        Swal.getInput().onkeyup = function (event) {
          let value = event.target.value;
          dom.querySelector(
            "#savingsStatus"
          ).innerHTML = `Amount in savings: ${(
            value * REDEEM_POINT
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}`;
        };
        Swal.getInput().onchange = function (event) {
          let value = event.target.value;
          dom.querySelector(
            "#savingsStatus"
          ).innerHTML = `Amount in savings: ${(
            value * REDEEM_POINT
          )?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}`;
        };
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
        if (value < 1) {
          return "Invalid points.";
        }
        if (value > customerProfile.current_points) {
          return "You dont have enough points.";
        }
      },
    });

    if (disc) {
      // Swal.fire({ html: `You selected: ${disc}` })
      makeRedemption({
        ...trans,
        points: disc,
        invoice_id: queryID,
        amount: disc * REDEEM_POINT,
        vin: serviceRequest.vin,
      });
    }
  };
  const applyDiscount = async (trans) => {
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          0: "",
          5: "5%",
          6: "6%",
          7: "7%",
          8: "8%",
          9: "9%",
          10: "10%",
          11: "11%",
          12: "12%",
          13: "13%",
          14: "14%",
          15: "15%",
          16: "16%",
          17: "17%",
          18: "18%",
          19: "19%",
          20: "20%",
        });
      }, 200);
    });

    const { value: disc } = await Swal.fire({
      title: "Select discount",
      input: "select",
      inputOptions: inputOptions,
      html: '<div id="calc_discount"></div>',
      didOpen: (dom) => {
        Swal.getInput().onchange = function (event) {
          console.log(event.target.value);
          // if (event.target.value === "Others") {
          dom.querySelector("#calc_discount").innerHTML = (
            (totals.labour + totals.product) *
            (event.target.value / 100)
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
          // } else {
          //   dom.querySelector('#fruit-text').style.display = 'none'
          // }
        };
      },
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
    });

    if (disc) {
      // Swal.fire({ html: `You selected: ${disc}` })
      makeDiscount({
        ...trans,
        invoice_id: queryID,
        amount: (totals.labour + totals.product) * (disc / 100),
        vin: serviceRequest.vin,
      });
    }
  };

  /* Save Payment */
  const makePayment = async (data) => {
    console.log(data);
    // console.log(data.replace("#", ""));
    // let state = e;
    // if (e.target) state = e.target.value;
    if (!data) return;

    try {
      const mydata = await dataProvider("post", "/transaction", data);
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      // loadData();
      fetchInvoice();
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  };
  /* Save Discount */
  const makeDiscount = async (data) => {
    console.log(data);
    // console.log(data.replace("#", ""));
    // let state = e;
    // if (e.target) state = e.target.value;
    if (!data) return;

    try {
      const mydata = await dataProvider("post", "/discount", data);
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      // loadData();
      fetchInvoice();
    } catch (error) {
      console.log(error);
      setToastMessage("Could not apply discount.");
      setIsToastOpen(true);
    }
  };
  
  /* Save Redemption */
  const makeRedemption = async (data) => {
    console.log(data);
    // console.log(data.replace("#", ""));
    // let state = e;
    // if (e.target) state = e.target.value;
    if (!data) return;

    try {
      const mydata = await dataProvider("post", "/redemption", data);
      console.log(mydata.data.message);
      setToastMessage(mydata.data.message);
      setIsToastOpen(true);
      fetchInvoice();
    } catch (error) {
      console.log(error);
      setToastMessage("Could not apply points.");
      setIsToastOpen(true);
    }
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -90,
    });
  };
  const handleDialogAgree = () => {
    setIsOpen(false);
    if (!formData.work_hour || formData.work_hour.length < 1) {
      doToast("Enter work hours");
      scrolly("work_hour");
      return false;
    }
    if (!formData.rate_hour || formData.rate_hour.length < 1) {
      doToast("Enter rate/hour");
      scrolly("rate_hour");
      return false;
    }

    dataProvider("put", "/invoice-details", {
      id: queryID,
      serviceRequest,
      formData,
      productInvoice,
      totals,
    })
      .then((res) => {
        // if (res.data.data.invoice_id) {
        //   setToastMessage(res.data.message);
        //   setIsToastOpen(true);
        // } else {
        // console.log(res.response, "imple")
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        // }
      })
      .catch((error) => {
        console.log(error);
        console.log(error, "post");
        setToastMessage(error.message);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  };

  // const reset = () => {
  //   Array.from(document.querySelectorAll("input")).forEach(
  //     (input) => (input.value = "")
  //   );
  //   setFormData({});
  // };

  const doToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };
  const handleDialogCancel = () => {
    // window.alert('cancel');
    setIsOpen(false);
  };
  // -----------------------------------------------------

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  //Update work hours Labour Total
  const handleFormChange = (event) => {
    if (event.target.name === "work_hour") {
      updateWorkHour(event.target.value);
    }
    if (event.target.name === "rate_hour") {
      updateRateHour(event.target.value);
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const updateWorkHour = (hours) => {
    if (hours < 0) return false;
    var labour_total = formData.rate_hour * hours;
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = labour_total + product_total;
    setTotals({
      ...totals,
      product: product_total,
      total: all_total,
      labour: labour_total,
    });
    setFormData({ ...formData, work_hour: hours });
    console.log(formData);
    // reCalculateTotals();
  };
  const updateRateHour = (rate) => {
    if (rate < 0) return false;
    var labour_total = formData.work_hour * rate;
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      product_total += element.qty * element.price;
    });
    var all_total = labour_total + product_total;
    setTotals({
      ...totals,
      product: product_total,
      total: all_total,
      labour: labour_total,
    });
    setFormData({ ...formData, rate_hour: rate });
    console.log(formData);
    // reCalculateTotals();
  };

  useEffect(() => {
    scrolly("pageTop");

    fetchInvoice();
    // getJob();
    loadProducts(); // Products inventory
  }, []);

  // Update Totals when product list is modified
  useEffect(() => {
    /* set product total */
    // reCalculateTotals();
    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      //  console.log(element, "ele");
      product_total += element.qty * element.price;
    });
    var all_total = totals?.labour + product_total;
    setTotals({ ...totals, product: product_total, total: all_total });
    // setTotals({...totals})
  }, [productInvoice]);

  /**
   * Re-calculate product total and consequently all TOTALS
   * @returns object
   */
  const reCalculateTotals = () => {
    var product_total = 0;
    productInvoice.forEach((element) => {
      //   console.log(element.price, "ele");
      product_total += element.qty * element.price;
    });
    console.log("Just did pro tot", product_total);
    var all_total = totals?.labour + product_total;
    setTotals({ ...totals, product: product_total, total: all_total });
  };

  /**
   * Get Job details
   * @returns null
   */
  function getJob(id) {
    // if (!queryID) return;

    dataProvider("post", "/booking", { id: id })
      .then((res) => {
        // console.log(res.status," RES")
        if (res.message === "Network Error") throw new Error(res.message);

        if (res.data) {
          console.log(res.data[0]);
          setServiceRequest(res.data[0]);
        } else {
          alert("No record found " + id);
          //   history.goBack();
        }
      })
      .catch((error) => {
        console.log(error, "post");
        setToastMessage(error);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  }

  /**
   * 
   * @returns Diable invoice udates
   */

  function disableInvoice(){
    if(payment === 0 && formData.discount === 0){
      return true;
    } else {
      return false;
    }
  }

  /**
   * Get Invoice Data
   * @returns object
   */
  function fetchInvoice() {
    if (!queryID) return;

    dataProvider("get", "/invoice/" + queryID, {})
      .then((res) => {
        // console.log(res.status," RES")
        if (res.message === "Network Error") throw new Error(res.message);

        if (res.data) {
          //   setPreInvoice(res.data[0]);
          //fetch and set LIVE service requests
          getJob(JSON.parse(res.data[0].profile).id);
          //set products used
          setProductInvoice(JSON.parse(res.data[0].products));

          //set invoiceToken
          setInvoiceData({ token: res.data[0].token });
          //update work hour
          updateWorkHour(res.data[0].work_hour);
          //update rate hour
          updateRateHour(res.data[0].rate_hour);
          //set form Data (Work hour)
          setFormData({
            work_hour: res.data[0].work_hour,
            rate_hour: res.data[0].rate_hour,
            discount: res.data[0].discount,
          });

          // let new_total = res.data[0].totals.total - res.data[0].payment;
          // setFullTotal({total:new_total, payment:res.data[0].payment});
          setPayment(res.data[0].payment);
          let totals_obj = JSON.parse(res.data[0].totals);
          setTotals(totals_obj); //apply original saved totals

          //Check if  full payment was made
          // console.log(totals_obj.total +" -" +res.data[0].payment);

          if (totals_obj.total <= 0) {
            setPaid(true);
          }
        } else {
          alert("No record found " + queryID);
          //   history.goBack();
        }
      })
      .catch((error) => {
        // console.log(error, "post");
        setToastMessage(error);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  }

  /**
   * Get all Products
   * @returns object
   */
  async function loadProducts() {
    try {
      const mydata = await dataProvider("get", "/products", []);
      setProducts(mydata.data);
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  function mainTableUp(v) {
    if (v[0]?.qty < 1) return false;
    // console.log(v, "------------");

    /* set product total */
    var product_total = 0;
    productInvoice.forEach((element) => {
      //   console.log(element, "ele");
      product_total += element.qty * element.price;
    });
    var all_total = totals?.labour + product_total;
    setTotals({ ...totals, product: product_total, total: all_total });
    setProductInvoice([...v]);

    // setTotals({...totals, product: tot})
  }

  function refreshData() {
    console.log(3);
  }
  return serviceRequest?.vin ? (
    <div className={classes.root} id="pageTop">
      <form className={classes.formRoot} autoComplete="off">
        <Grid container spacing={3} style={{ paddingTop: 30 }}>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="h2"
                    style={{ fontSize: "2em" }}
                  >
                    Invoice #{queryID}
                  </Typography>
                </Grid>
                {/* <Grid item>1
                  <Typography gutterBottom variant="h6">
                    $4.50
                  </Typography>
                </Grid> */}
              </Grid>

              <Divider
                style={{
                  marginTop: 15,
                  background: "#f1f1f1",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <ServiceIcon fontSize="large" className={classes.icon}/> */}
                  <img alt="product" src={icon} width={60} />
                  <Typography gutterBottom variant="h5">
                    {serviceRequest?.first_name +
                      " " +
                      serviceRequest?.last_name}
                  </Typography>
                </Grid>
                {/* <Grid item>
            <Typography gutterBottom variant="h6">
              $4.50
            </Typography>
          </Grid> */}
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Enter work hours and select products used for this job.
              </Typography>
              {/* <Divider style={{ marginTop: 15 }} /> */}
            </div>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "left" }}>
            {/* <Typography variant="h6">{serviceRequest.first_name} {serviceRequest.last_name}  </Typography> */}
            <small className={classes.hint}>Registration</small>
            <Typography variant="subtitle1">
              {serviceRequest.registration}{" "}
            </Typography>
            <small className={classes.hint}>Contact</small>
            <Typography variant="subtitle1">
              {serviceRequest.contact}{" "}
            </Typography>
            {/* <Typography variant="subtitle1">{serviceRequest.registration} </Typography> */}
            <small className={classes.hint}>Email</small>
            <Typography variant="subtitle1">{serviceRequest.email} </Typography>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "left" }}>
            <small className={classes.hint}>VIN</small>
            <Typography variant="subtitle1">{serviceRequest.vin} </Typography>
            <small className={classes.hint}>Mileage</small>
            <Typography variant="subtitle1">
              {serviceRequest.mileage}{" "}
            </Typography>
            <small className={classes.hint}>Services</small>
            <Typography variant="subtitle1" color="primary">
              {serviceRequest.service}{" "}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} style={{ textAlign: "left" }}>
            <TextField
              label="Enter work hours"
              name="work_hour"
              id="work_hour"
              variant="outlined"
              className={classes.flow}
              value={formData.work_hour}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  return handleFormChange(e);
                }
              }}
              disabled={disableInvoice() ? false : true}
              required
              type="number"
              inputProps={{ maxLength: 9, min: 0 }}
              autoComplete="off"
            />
            <small className={classes.hint}>
              {/* {formData.rate_hour
                ? formData.rate_hour.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  }) + "/hr"
                : formData.rate_hour} */}
              {formData.rate_hour?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) + "/hr"}
            </small>
          </Grid>

          <Grid item xs={12} md={3} style={{ textAlign: "left" }}>
            <TextField
              label="Enter rate/hour"
              name="rate_hour"
              id="rate_hour"
              variant="outlined"
              className={classes.flow}
              value={formData.rate_hour}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  return handleFormChange(e);
                }
              }}
              disabled={disableInvoice()? false : true}
              required
              type="number"
              inputProps={{ maxLength: 9, min: 0, max: 9999999999, step: 1 }}
              autoComplete="off"
            />
          </Grid>

          {/* <Grid item xs={12} md={3} style={{ textAlign: "left" }}>
          <InputLabel id="discount_label">Apply discount</InputLabel>
        <Select
          labelId="discount"
          id="discount"
          name="discount"
          value={formData.discount}
          onChange={handleFormChange}
          label="Apply discount"
        >
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={500}>Five Hundred ($500)</MenuItem>
          <MenuItem value={1000}>One Thousand ($1,000)</MenuItem>
          <MenuItem value={1500}>One Thousand Five Hundred ($1,500)</MenuItem>
        </Select>
        
          </Grid> */}

          <Grid item xs={12} md={6}>
            <Autocomplete
              id="product"
              disabled={disableInvoice() ? false : true}
              //   onChange={productUpdate}
              onChange={(event, newValue) => {
                if (!newValue) return;
                var filtr = productInvoice.filter(
                  (item) => item.id === newValue.id
                );
                if (filtr.length > 0) return; // product already in the list dont add
                var newTableData = productInvoice;
                newTableData.splice(0, 0, { qty: 1, ...newValue }); //add new product to the top of invoice
                setProductInvoice([...newTableData]);
                // setProductValue(productValue);
              }}
              //   value={productValue}
              options={products}
              getOptionLabel={(option) => {
                return (
                  option.title +
                  " - " +
                  option.price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                );
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <div>{option.title}</div>&nbsp;
                  <small style={{ color: "#999", float: "right" }}>
                    ({option.description})
                  </small>
                  &nbsp;-&nbsp;
                  <small
                    style={{
                      color: "#1a6ba8",
                      float: "right",
                      fontWeight: 700,
                    }}
                  >
                    {option.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </small>
                </React.Fragment>
              )}
              //   style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose products"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} style={{ marginTop: 20 }}>
            <DataTable
              data={productInvoice}
              refresher={refreshData}
              option={option}
              tableUp={mainTableUp}
              active={disableInvoice() ? false : true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: "left", marginTop: 15 }}
          >
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow key="1">
                      <TableCell align="left">
                        <b>Products Total</b>
                      </TableCell>
                      <TableCell align="left">
                        {totals?.product.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow key="2">
                      <TableCell align="left">
                        <div>
                          <b>Labour Total</b>
                        </div>
                        <small>
                          {formData.rate_hour
                            ? formData.rate_hour.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              }) + " / hr"
                            : formData.rate_hour}
                        </small>
                      </TableCell>
                      <TableCell align="left">
                        {totals.labour.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow key="3" style={{ background: "#F5FCD9" }}>
                      <TableCell align="left">
                        <div>
                          <b>Amount Paid</b>
                        </div>
                        {/* <small>
                          {getSettings().rate
                            ? getSettings().rate.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              }) + " / hr"
                            : "Please restart application"}
                        </small> */}
                      </TableCell>
                      <TableCell align="right" style={{ color: "green" }}>
                        <strong>
                          {payment.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </strong>
                      </TableCell>
                    </TableRow>

                    <TableRow key="4" style={{ background: "#eaf8ff" }}>
                      <TableCell align="left">
                        <div>
                          <b>Discount</b>
                        </div>
                        {/* <small>
                          {getSettings().rate
                            ? getSettings().rate.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              }) + " / hr"
                            : "Please restart application"}
                        </small> */}
                      </TableCell>
                      <TableCell align="right" style={{ color: "#222" }}>
                        <strong>
                          {formData.discount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </strong>
                      </TableCell>
                    </TableRow>

                    <TableRow key="5">
                      <TableCell align="left">
                        <b>Total Due</b>
                      </TableCell>
                      <TableCell align="left">
                        <strong
                          style={{
                            fontSize: "1.2em",
                            color: totals.total === 0 ? "#222" : "#F50359",
                          }}
                        >
                          {totals.total === 0 ? (
                            <div style={{ color: "green" }}>PAID</div>
                          ) : (
                            totals.total.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          )}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={12} style={{ marginTop: 9, padding: 5 }}>
                <Paper style={{ padding: 3, backgroundColor: "#f6f6f6" }}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => history.push("/view-invoices")}
                    style={{ margin: 9 }}
                  >
                    View Invoices
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    // color="primary"
                    onClick={
                      () => getPDF_(config.domain + "invoice-pdf/" + queryID)
                      // getPDF_(config.domain + "invoice-pdf/" + queryID)
                      // console.log(config.domain + "invoice-pdf/" + queryID)
                    }
                    style={{ margin: 9 }}
                    startIcon={<Description />}
                  >
                    Print Invoice
                  </Button>
                  {/* {invoiceData.token !== null?
                  <Button
                    variant="contained"
                    size="large"
                    // color="primary"
                    onClick={() =>
                      // getPDF_(config.domain + "invoice-pdf/" + queryID)
                      whatsappInvoice(config.userInvoice + invoiceData.token, serviceRequest.contact)
                      // console.log(config.domain + "invoice-pdf/" + queryID)
                    }
                    style={{ margin: 9 }}
                    startIcon={<WhatsApp />}
                  >
                    WhatsApp Invoice
                  </Button>:''} */}
                  {/* {invoiceData.token !== null && serviceRequest.email !== ''?
                  <Button
                    variant="contained"
                    size="large"
                    // color="primary"
                    onClick={() =>
                      // getPDF_(config.domain + "invoice-pdf/" + queryID)
                      emailInvoice(config.userInvoice + invoiceData.token, serviceRequest.contact)
                      // console.log(config.domain + "invoice-pdf/" + queryID)
                    }
                    style={{ margin: 9 }}
                    startIcon={<Email />}
                  >
                    Email Invoice
                  </Button>:''} */}
                  <Button
                    variant="contained"
                    size="large"
                    // color="primary"
                    onClick={() => applyDiscount({ method: "discount" })}
                    style={{ margin: 9, display: paid === true ? "none" : "" }}
                    startIcon={<MoneyOff />}
                  >
                    Apply Discount
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    // color="primary"
                    onClick={() => applyPoints({ method: "redemption" })}
                    style={{ margin: 9, display: paid === true ? "none" : "" }}
                    startIcon={<Loyalty />}
                  >
                    Apply Points
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleDialogOpen}
                    style={{ margin: 9, display: paid === true ? "none" : "" }}
                  >
                    Save Changes
                  </Button>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: 19,
                  padding: 5,
                  display: paid === true ? "none" : "",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  // style={{ fontSize: "2em" }}
                >
                  Payment methods
                </Typography>

                {/* <Divider  style={{ marginBottom: 9 }} /> */}
                <Paper style={{ padding: 9, backgroundColor: "#f6f6f6" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "cash" })}
                        startIcon={<Money />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#98c60b",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Cash
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "credit-card" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#ff5919",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Credit Card
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "debit-card" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#01baf1",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Debit Card
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "cheque" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#d604d7",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Cheque
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => paymentDialog({ method: "online" })}
                        startIcon={<CreditCard />}
                        style={{
                          // margin: 9,
                          fontWeight: 550,
                          backgroundColor: "#787b84",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Online
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
         
        </Grid> */}
      </form>
      <AlertDialog
        isOpen={isOpen}
        handleClose={handleDialogClose}
        handleAgree={handleDialogAgree}
        handleCancel={handleDialogCancel}
        title={
          "Scott's Auto -  Invoice for " +
          serviceRequest.first_name +
          " " +
          serviceRequest.last_name
        }
        subtitle="Are you sure you want to continue?"
      />
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  ) : (
    <LinearProgress style={{ marginTop: 60 }} />
  );
}
