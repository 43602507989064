import React, { useState, useEffect } from "react";

import {
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  getBookings,
  getServiceList,
  putServiceItem,
  doAPI,
  getProfile
} from "../util";
import Toast from "../components/Toast";
import Modal from "@material-ui/core/Modal";
// import AddBox from "@material-ui/icons/AddBox";
// import ArrowDownward from "@material-ui/icons/ArrowDownward";
// import Check from "@material-ui/icons/Check";
// import ChevronLeft from "@material-ui/icons/ChevronLeft";
// import ChevronRight from "@material-ui/icons/ChevronRight";
// import Clear from "@material-ui/icons/Clear";
// import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
// import EditRounded from "@material-ui/icons/EditRounded";
// import FilterList from "@material-ui/icons/FilterList";
// import FirstPage from "@material-ui/icons/FirstPage";
// import LastPage from "@material-ui/icons/LastPage";
// import Remove from "@material-ui/icons/Remove";
// import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
// import SelectAll from "@material-ui/icons/SelectAll";
// import ViewColumn from "@material-ui/icons/ViewColumn";
// import { Refresh, Note, Comment, EditRounded } from "@material-ui/icons";
import MyTable from "../components/MyTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
  paper: {
    position: "absolute",
    width: "auto",
    maxHeight: "100vh",
    top: 20,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #666",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
    // paddingBottom:20,
    // marginBottom:20
  },
}));

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    overflow: "scroll",
    top: `${top}%`,
    left: `${left}%`,
    bottom: 30,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function Bookings() {
  const classes = useStyles();
  const [serviceList, setServiceList] = React.useState([]);
  const [modalScreen, setModalScreen] = React.useState("services");
  const [subject, setSubject] = React.useState({});
  const [service, setService] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  // const [tableconfig, setTableConfig] = React.useState({ title: "Mileage", field: "mileage"});
  
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [bookings, setBookings] = useState([]);
  const [mcomment, setComment] = useState("");
  const handleComment = (e) => {
    // console.log(e.target.value);
    setComment(e.target.value);
  };

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
  }, []);
 
  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }


  // useEffect(() => {
  //   console.log(mcomment, "-----------------comup");
  // }, [mcomment]);

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------
 
  function loadBookings() {
    getBookings()
      .then((res) => {
        setBookings(res.data);
      })
      .catch((error) => {
        setToastMessage(error.message);
        setIsToastOpen(true);
      });
  }

  const [modalStyle] = React.useState(getModalStyle);
  const handleChange = (event) => {
    // if(event.target.checked === true) {
    // console.log(event.target.name);
    console.log(event.target.checked);
    // console.log(service,"service--");
    setService({ ...service, [event.target.name]: event.target.checked });
    // }
  };

  // Services edit Option
  useEffect(() => {
    async function fetchServices() {
      // if (sessionStorage.getItem("services")) {
      //   let session_data = JSON.parse(sessionStorage.getItem("services"));
      //   setServiceList(session_data.data);
      //   // scrolly("pageTop");
      // } else {
        try {
          let live_data = await getServiceList();
          if (typeof live_data === "undefined" || live_data === "Network Error")
            throw new Error("Please restart application. Network Error.");
          // console.log(1);
          sessionStorage.setItem("services", JSON.stringify(live_data));
          setServiceList(live_data.data);
          // scrolly("pageTop");
        } catch (error) {
          setToastMessage(error.message);
          setIsToastOpen(true);
        }
      // }
    }
    loadBookings();
    fetchServices();

    //editable config
    
  }, []);


  const updateServices = () => {
    console.log(subject.id);

    let save_service = [];
    Object.entries(service).forEach((v, i, a) => {
      // console.log(v);
      if (v[1] === true) {
        save_service.push(v[0]);
      }
    });
    console.log(save_service);
    putServiceItem({ customer: subject, service: save_service })
      .then((res) => {
        // console.log(res)
        // setIsOpen(false);
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setTimeout(() => {
          handleCloseModal();
          loadBookings();

          // console.log(tableRef);

          // window.location.reload();
          // window.location.href = "/make-request";
        }, 1000);
      })
      .catch((error) => {
        // setIsOpen(false);
        setToastMessage(error.message);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  };

  const updateComment = () => {
    // console.log(subject.id);
    doAPI("comment", { id: subject.id.replace("#", ""), value: mcomment })
    .then((res) => {
      setToastMessage(res.data.message);
      setIsToastOpen(true);
      setComment('');
      setTimeout(() => {
        handleCloseModal();
        loadBookings();
      }, 1000);
    })
    .catch((error) => {
      setToastMessage(error.data.message);
      setIsToastOpen(true);
    });

  };


  const executeServiceEdit = (data) => {
    setModalScreen("services");
    handleOpenModal();
    setSubject({ name: data.first_name, id: data.id });

    //generate true values for service check
    let check_services = {};
    const obj_service = JSON.parse(data.service);
    Object.entries(obj_service).forEach((v, i, a) => {
      check_services = { ...check_services, [v[1]]: true };
      console.log(v);
    });
    console.log(check_services);
    setService(check_services);
  };

  const executeCommentEdit = (data) => {
    setModalScreen("comment");
    handleOpenModal();
    setSubject({ name: data.first_name, id: data.id });
    setComment(data.comment);
    // document.querySelector("#comment").value = data.comment;
    //generate true values for service check
    // let check_services = {};
    // const obj_service = JSON.parse(data.service);
    // Object.entries(obj_service).forEach((v, i, a) => {
    //   check_services = { ...check_services, [v[1]]: true };
    //   console.log(v);
    // });
    // console.log(check_services);
    // setService(check_services);
  };

  const tableRef = React.createRef();
  const op = ["received", "active", "complete"];

  const updateStatus = (data, e) => {
    console.log(e.target.value);
    console.log(data.replace("#", ""));
    doAPI("status", { id: data.replace("#", ""), status: e.target.value })
      .then((res) => {
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        setTimeout(() => {
          loadBookings();
        }, 1500);
      })
      .catch((error) => {
        setToastMessage(error.data.message);
        setIsToastOpen(true);
      });
  };

  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <MyTable data={bookings} refresher={loadBookings} option={{title: "Scott's Auto"}} />
        </Grid>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          <div
            // style={modalStyle}
            className={classes.paper}
            style={{ display: modalScreen === "comment" ? "block" : "none" }}
          >
            <h3 id="simple-modal-title">Make a comment for {subject.name}</h3>



            <Grid container spacing={3}>
              <Grid item xs={12}>
              <TextField
           
              label="Comment"
              variant="outlined"
              className={classes.flow}
              name="Comment"
              value={mcomment}
              onChange={handleComment}
              id="comment"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              rows={3}
              multiline
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
                // onBlur:(e) => commentBlur(userRowData.id, e)
              }}
            />
              </Grid>
            <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: 40 }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={updateComment}
                >
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          
          
          </div>

          <div
            // style={modalStyle}
            className={classes.paper}
            style={{...modalStyle, display: modalScreen === "services" ? "block" : "none" }}
          >
            <h3 id="simple-modal-title">Edit services for {subject.name}</h3>

            <Grid container spacing={3}>
              {serviceList?.map((data, index, arr) => {
                // setService({ ...service, [data.description]: false});

                return (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ textAlign: "left", border: "0px solid #f3f3f3" }}
                    >
                      <FormControlLabel
                        required
                        key={data.title}
                        control={
                          <Checkbox
                            key={data.title}
                            checked={
                              service && service[data.description]
                                ? service[data?.description]
                                : false
                            }
                            onChange={handleChange}
                            name={data.description}
                          />
                        }
                        label={
                          data.description[0] !==
                          arr[index - 1]?.description[0] ? (
                            <span>
                              <b
                                style={{
                                  color: "#000",
                                  fontSize: "1.2em",
                                  textDecoration: "underline",
                                }}
                              >
                                {data.description[0]}
                              </b>
                              {data.description.substring(1)}
                            </span>
                          ) : (
                            data.description
                          )
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}

              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: 40 }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={updateServices}
                >
                  Save
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      </Modal>

      {/* </form> */}
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
