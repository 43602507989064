import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Menu,
  MenuItem,
  Divider,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import {
  AddToQueue,
  FormatListNumbered,
  ExitToApp,
  PersonAdd,
  Group,
  Dashboard,
  Event,
  Description,
  Build,
  Storefront,
  LocalAtm,
  BarChart,
} from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../bla.svg";
import { doLogout, getProfile } from "../util";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color:"#666"
  },
  title: {
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Bar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{ background: "#fafafa" }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="logout"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {profile?.permission > 2 ? (
              <>
                <MenuItem
                  onClick={() => {
                    history.push("/dash");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Dashboard fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push("/view-calendar");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Event fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Calendar" />
                </MenuItem>
                <Divider />
              </>
            ) : (
              ""
            )}

            <Typography
              variant="button"
              style={{ marginLeft: 5, fontWeight: 600 }}
            >
              Customers
            </Typography>
            {profile?.permission > 1 ? (
              <MenuItem
                onClick={() => {
                  history.push("/add-customer");
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Add Customer" />
              </MenuItem>
            ) : (
              ""
            )}

            {profile?.permission > 2 ? (
              <>
                {/* <Typography
                  variant="button"
                  style={{ marginLeft: 5, fontWeight: 600 }}
                >
                  Customers
                </Typography> */}
                <MenuItem
                  onClick={() => {
                    history.push("/view-customers");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Group fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Customers" />
                </MenuItem>
                <Divider />
                <Typography
                  variant="button"
                  style={{ marginLeft: 5, fontWeight: 600 }}
                >
                  Service
                </Typography>
                <MenuItem
                  onClick={() => {
                    history.push("/make-request");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Build fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Make Service Request" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push("/view-request");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <FormatListNumbered fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Service Requests" />
                </MenuItem>
                <Divider />{" "}
              </>
            ) : (
              ""
            )}

            {profile?.permission > 3 ? (
              <>
                <Typography
                  variant="button"
                  style={{ marginLeft: 5, fontWeight: 600 }}
                >
                  Sales
                </Typography>
                <MenuItem
                  onClick={() => {
                    history.push("/view-invoices");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Description fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Invoices" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    history.push("/view-estimates");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Description fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Estimates" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    // history.push("/view-estimate");

                    window.location = "/view-estimate"
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <FormatListNumbered fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Generate Estimate" />
                </MenuItem>


                {profile?.permission > 4 ? (
                  <MenuItem
                    onClick={() => {
                      history.push("/transactions");
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <LocalAtm fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                  </MenuItem>
                ) : null}
                 {profile?.permission > 4 ? (
                  <MenuItem
                    onClick={() => {
                      history.push("/report");
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <BarChart fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                  </MenuItem>
                ) : null}
                <Divider />{" "}
              </>
            ) : (
              ""
            )}

            {profile?.permission > 3 ? (
              <>
                <Typography
                  variant="button"
                  style={{ marginLeft: 15, fontWeight: 600 }}
                >
                  Products
                </Typography>
                <MenuItem
                  onClick={() => {
                    history.push("/add-product");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Storefront fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Add Product" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push("/view-products");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <FormatListNumbered fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Products" />
                </MenuItem>
              </>
            ) : null}

            <Divider />

            <MenuItem onClick={doLogout}>
              <ListItemIcon>
                <ExitToApp fontSize="small" style={{ color: "orange" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
            {/* <MenuItem onClick={()=> {window.open("", "_self");
window.close();}}>
        <ListItemIcon>
            <PowerOff fontSize="small" style={{color:'red'}} />
          </ListItemIcon>
          <ListItemText primary="Exit" />
        </MenuItem> */}
          </Menu>

          
          <div style={{width:"100%"}}>
            <img
              alt="logo"
              height={42}
              style={{ verticalAlign: "middle", marginRight:48}}
              src={logo}
              onClick={()=>history.push("/dash")}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
