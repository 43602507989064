import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider } from "../util";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Swal from "sweetalert2";

var moment = require('moment')
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);





export default function Report() {
  // let history = useHistory();
  const classes = useStyles();
  const [titleData, setTitleData] = useState('');
  const [fdata, setData] = useState({});
  const [reportData, setReportData] = useState({});
  // const [totals, setTotals] = useState({labour: 0, products:0})

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales Report'+titleData,
      },
    },
  }

  const chart_data = {
    labels: reportData?.invoice?.map((i) => i.dt.substring(0,10) ),
    datasets: [
      {
        label: 'Labour',
        data: reportData?.invoice?.map((i) => i.labour),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Products',
        data: reportData?.invoice?.map((i) => i.products),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const formChange = (e) => {
    setData({ ...fdata, [e.target.name]: moment(e.target.value).format('YYYY-MM-DD')});
  };

  const report = async (e) => {
    if(!fdata.start_date) {
     return Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Select start date',
        showConfirmButton: false,
        timer: 1500
      })
      
    }  if(!fdata.end_date) {
      return Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Select end date',
        showConfirmButton: false,
        timer: 1500
      })
    }

    if(moment(fdata.end_date).diff(moment(fdata.start_date), 'days') > 365 ){
      return Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Do reports within 365 days',
        showConfirmButton: false,
        timer: 1500
      })
    }

    try {
      const mydata = await dataProvider("post", "/report", fdata);
      // console.log(mydata.data);
      var p = 0;
      var l = 0;
      mydata.data.invoice.map((i) => {
        p += i.products;
        l += i.labour;
      })
      setTitleData(` - Labour: ${l?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })} Products: ${p?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`)
      // setTotals({labour:l, products:p})
      setReportData(mydata.data);
    } catch (error) {
      console.log(error);
    }
  };

  
  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h2" style={{ fontSize: "2em" }}>
            Reports
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="start_date"
            name="start_date"
            label="Start"
            type="date"
            onChange={formChange}
            // defaultValue="2017-05-24"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <TextField
            id="end_date"
            name="end_date"
            label="End"
            type="date"
            onChange={formChange}
            // defaultValue="2017-05-24"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            name="sales"
            variant="contained"
            value="sales"
            color="primary"
            onClick={() => report("sales")}
          >
            Sales Report
          </Button>
        </Grid>
        
         <Grid item xs={12}>
         <Bar options={options} data={chart_data} />
        </Grid>
      </Grid>
      {/* </form> */}
    </div>
  );
}
