import React, { useState, useEffect } from "react";
import { dataProvider, getProfile, doRequest } from "../util";
import Toast from "../components/Toast";
import MyTable from "../components/MyTable";
import {
  Grid,
  Typography,
  Paper,
  Avatar,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Build,
  Today,
  AssignmentTurnedIn,
  DriveEta,
  Storefront,
  Money,
} from "@material-ui/icons";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 15,
    paddingTop: 50,
    backgroundColor: "#F4F6F8",
  },
  formRoot: {
    "& > *": {},
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
  paper: {
    position: "absolute",
    width: "auto",
    maxHeight: "100vh",
    top: 20,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #666",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "scroll",
  },
  newpaper: {
    // position: "absolute",
    width: "auto",
    maxHeight: "100vh",
    // top: 20,
    textAlign: "left",
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #aaa",
    // boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 3, 2),
    // overflow: "scroll",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState({
    title: "Active Customers",
    id: "active",
  });
  const [dash, setDash] = useState({
    active: "-",
    received: "-",
    complete: "-",
    booked: "-",
    products: "-",
    labour: "-",
    sales: "-",
    discounts: "-",
  });

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  useEffect(() => {
    // setData([]);
    loadProfile();
    loadData({ status: "active" });
    storeSettings();
    loadDash();
  }, []);

  useEffect(() => {
    // console.log(dash,"dd")
  }, [dash]);

  async function storeSettings() {
    if (!sessionStorage.getItem("settings")) {
      try {
        const mydata = await dataProvider("get", "/settings", []);
        console.log(mydata.status, "prez");
        if (mydata === undefined) {
          setToastMessage("Please restart application.");
          setIsToastOpen(true);
          return;
        }
        sessionStorage.setItem("settings", JSON.stringify(mydata.data));
      } catch (error) {
        // console.log(error.message);
        setToastMessage(error.message);
        setIsToastOpen(true);
      }
    }
  }

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }
  const loadDash = async () => {
    try {
      const p = await getProfile();
      let res = await dataProvider("get", "/dash", {});
      let res_report = null;
      console.log(p.permission, "permission");
      if (p.permission > 4) {
        res_report = await dataProvider("get", "/dash-report", {});
      }

      if (res.status !== 200) {
        setToastMessage(res.response);
        setIsToastOpen(true);
        return;
      }
      // console.log(res.data)

      setDash({
        active: res.data[0][0].active,
        received: res.data[1][0].received,
        complete: res.data[2][0].complete,
        booked: res.data[3][0].booked,
        labour: res_report?.data?.invoice[0].labour
          ? res_report.data.invoice[0].labour
          : 0,
        products: res_report?.data?.invoice[0].products
          ? res_report.data.invoice[0].products
          : 0,
        sales: res_report?.data?.sales[0].sales
          ? res_report.data.sales[0].sales
          : 0,
        discounts: res_report?.data?.discounts[0].discounts
          ? res_report.data.discounts[0].discounts
          : 0,
      });
    } catch (error) {
      console.log(error, "er");
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  };
  // const loadDashFinancials = async() => {
  //   try {
  //       let res = await dataProvider('get', '/dash-report', {});

  //       if(res.status !== 200){

  //           setToastMessage(res.response);
  //           setIsToastOpen(true);
  //           return;
  //       }
  //       console.log(res.data, "FIna")
  //       setDash((prev) =>
  //         ({ ...prev,
  //           labour:res.data.invoice[0].labour,
  //           products:res.data.invoice[0].products,
  //           sales:res.data.sales[0].sales,
  //         })
  //       );
  //   } catch (error) {
  //     console.log(error, "er")
  //     setToastMessage(error.message);
  //       setIsToastOpen(true);
  //   }

  // }
  async function loadData(value) {
    let live = true;
    setTimeout(() => {
      if (live) {
        setIsLoading(true);
      }
    }, 1000);
    try {
      const mydata = await dataProvider("post", "/bookings-fetch", value);
      live = false;
      if (mydata.status !== 200) {
        setToastMessage(mydata.data);
        setIsToastOpen(true);
        return false;
      }
      setData([]);
      setData(mydata.data);
      setOption({
        title:
          value.status.charAt(0).toUpperCase() +
          value.status.slice(1) +
          " Customers",
        id: value.status,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
      setIsLoading(false);
    }
  }

  const refreshData = async () => {
    try {
      loadDash();
      const mydata = await dataProvider("post", "/bookings-fetch", {
        status: option.id,
      });
      if (mydata.status !== 200) {
        setToastMessage(mydata.data);
        setIsToastOpen(true);
        return false;
      }
      setData([]);
      setData(mydata.data);
    } catch (error) {
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  };

  const cardClick = (e) => {
    // doRequest("POST", "/v2/inventory", {}).then((res) => {
    //   if(!res) return false;
    //   const {data} = res;
    //   Swal.fire({
    //     text:data?.message,
    //     icon:'info'
    //   })
    // })
  };

  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <Typography gutterBottom variant="h2" style={{ fontSize: "2em" }}>
            Dashboard
          </Typography> */}
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Paper
            className={classes.newpaper}
            onClick={() => loadData({ status: "active" })}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="button"
                  style={{ color: "#98A3B4" }}
                >
                  Active
                </Typography>
                <Typography
                  gutterBottom
                  variant="h4"
                  style={{
                    color: "#5a6169",
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  {dash.active}
                  <Avatar style={{ background: "#F68E4F", float: "right" }}>
                    <Build />
                  </Avatar>
                </Typography>
                <small
                  style={{ float: "left", fontSize: "0.6em", color: "#a6abb1" }}
                >
                  All Time
                </small>
              </Grid>
              {/* <Grid item xs={2}>
                
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Paper
            className={classes.newpaper}
            onClick={() => loadData({ status: "complete" })}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="button"
                  style={{ color: "#98A3B4" }}
                >
                  Complete
                </Typography>

                <Typography
                  gutterBottom
                  variant="h4"
                  style={{
                    color: "#5a6169",
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  {dash.complete}

                  <Avatar style={{ background: "#33C4B3", float: "right" }}>
                    <AssignmentTurnedIn />
                  </Avatar>
                </Typography>
                <small
                  style={{ float: "left", fontSize: "0.6em", color: "#00bde9" }}
                >
                  Today
                </small>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Paper
            className={classes.newpaper}
            onClick={() => loadData({ status: "received" })}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="button"
                  style={{ color: "#98A3B4" }}
                >
                  Received
                </Typography>
                <Typography
                  gutterBottom
                  variant="h4"
                  style={{
                    color: "#5a6169",
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  {dash.received}{" "}
                  <Avatar style={{ background: "#666", float: "right" }}>
                    <DriveEta />
                  </Avatar>
                </Typography>
                <small
                  style={{ float: "left", fontSize: "0.6em", color: "#a6abb1" }}
                >
                  All Time
                </small>
              </Grid>
              {/* <Grid item xs={4}>
               
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <Paper
            className={classes.newpaper}
            onClick={() => loadData({ status: "booked" })}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="button"
                  style={{ color: "#98A3B4" }}
                >
                  Bookings
                </Typography>
                <Typography
                  gutterBottom
                  variant="h4"
                  style={{
                    color: "#5a6169",
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                >
                  {dash.booked}
                  <Avatar style={{ background: "#0CBBD8", float: "right" }}>
                    <Today />
                  </Avatar>
                </Typography>
                <small
                  style={{ float: "left", fontSize: "0.6em", color: "#00bde9" }}
                >
                  Today
                </small>
              </Grid>
              {/* <Grid item xs={4}>
                
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
        {profile?.permission > 4 ? (
          <>
            <Grid item xs={6} sm={4} md={3}>
              <Paper className={classes.newpaper} onClick={cardClick}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      variant="button"
                      style={{ color: "#98A3B4" }}
                    >
                      Products
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{
                        color: "#5a6169",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      {dash.products?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                      <Avatar style={{ background: "#3f51b5", float: "right" }}>
                        <Storefront />
                      </Avatar>
                    </Typography>
                    <small
                      style={{
                        float: "left",
                        fontSize: "0.6em",
                        color: "#00bde9",
                      }}
                    >
                      Today (Paid-Full)
                    </small>
                  </Grid>
                  {/* <Grid item xs={4}>
                
              </Grid> */}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={6} sm={4} md={3}>
              <Paper className={classes.newpaper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      variant="button"
                      style={{ color: "#98A3B4" }}
                    >
                      Labour
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{
                        color: "#5a6169",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      {dash.labour?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                      <Avatar style={{ background: "#ed1142", float: "right" }}>
                        <Build />
                      </Avatar>
                    </Typography>
                    <small
                      style={{
                        float: "left",
                        fontSize: "0.6em",
                        color: "#00bde9",
                      }}
                    >
                      Today (Paid-Full)
                    </small>
                  </Grid>
                  {/* <Grid item xs={4}>
                
              </Grid> */}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Paper className={classes.newpaper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      variant="button"
                      style={{ color: "#98A3B4" }}
                    >
                      Sales
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{
                        color: "#5a6169",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      {dash.sales?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                      <Avatar style={{ background: "#98c60b", float: "right" }}>
                        <Money />
                      </Avatar>
                    </Typography>
                    <small
                      style={{
                        float: "left",
                        fontSize: "0.6em",
                        color: "#00bde9",
                      }}
                    >
                      Today (All)
                    </small>
                  </Grid>
                  {/* <Grid item xs={4}>
                
              </Grid> */}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Paper className={classes.newpaper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      variant="button"
                      style={{ color: "#98A3B4" }}
                    >
                      Discounts
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{
                        color: "hwb(347deg 8% 8%)",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      {dash.discounts?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                      <Avatar style={{ background: "#815DA3", float: "right" }}>
                        <Money />
                      </Avatar>
                    </Typography>
                    <small
                      style={{
                        float: "left",
                        fontSize: "0.6em",
                        color: "#00bde9",
                      }}
                    >
                      Today (All)
                    </small>
                  </Grid>
                  {/* <Grid item xs={4}>
                
              </Grid> */}
                </Grid>
              </Paper>
            </Grid>
          </>
        ) : (
          ""
        )}

        <Grid item xs={12}>
          <LinearProgress
            style={{ visibility: isLoading ? "visible" : "hidden" }}
          />
          <MyTable data={data} refresher={refreshData} option={option} />
        </Grid>
      </Grid>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
