import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Bar from "./components/Bar";
import AddCustomer from "./pages/AddCustomer";
import AddProduct from "./pages/AddProduct";
import ViewCustomers from "./pages/ViewCustomers";
import ViewProducts from "./pages/ViewProducts";
import ViewInvoices from "./pages/ViewInvoices";
import ViewEstimate from "./pages/ViewEstimate";
import ViewEstimates from "./pages/ViewEstimates";
import ViewInvoice from "./pages/ViewInvoice";
import ServiceRequest from "./pages/ServiceRequest";
import Dashboard from "./pages/Dashboard";
import ViewCalendar from "./pages/ViewCalendar";
import Transactions from "./pages/Transactions";
// import EditServices from "./pages/EditServices";
import Login from "./pages/Login";
import Bookings from "./pages/Bookings";
// import Footer from "./components/Footer";
// import {checkAuth} from './util';
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
// import { getProfile } from "../util";
import { getProfile } from "./util";
import GenerateInvoice from "./pages/GenerateInvoice";
import Report from "./pages/Report";
// import { dataProvider } from "./util";
// import withClearCache from "./ClearCache";

// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }

// function MainApp(props) {
function App() {
  const [profile, setProfile] = React.useState({});
  useEffect(() => {

    loadProfile();
  }, []);

  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }



  return (
    <div className="App">
      <Router>
        <Bar />
        <Switch>
          <PrivateRoute
            component={profile.permission > 2 ? Dashboard : ServiceRequest}
            path="/"
            permission={1}
            exact
          />
          <PrivateRoute
            component={ServiceRequest}
            path="/make-request"
            permission={1}
            exact
          />
          <PrivateRoute
            component={Dashboard}
            permission={2}
            path="/dash"
            exact
          />
          <PrivateRoute
            component={ViewCalendar}
            permission={2}
            path="/view-calendar"
            exact
          />
          <PrivateRoute
            component={Bookings}
            permission={3}
            path="/view-request"
            exact
          />
          {/* <PrivateRoute
            component={EditServices}
            permission={3}
            path="/edit-services"
            exact
          /> */}
           <PrivateRoute
            component={Report}
            permission={3}
            path="/report"
            exact
          />
          <PrivateRoute
            component={AddCustomer}
            permission={1}
            path="/add-customer"
            exact
          />
          <PrivateRoute
            component={ViewCustomers}
            permission={3}
            path="/view-customers"
            exact
          />
          <PrivateRoute
            component={AddProduct}
            permission={3}
            path="/add-product"
            exact
          />{" "}
          <PrivateRoute
            component={ViewProducts}
            permission={3}
            path="/view-products"
            exact
          /> 
          <PrivateRoute
            component={GenerateInvoice}
            permission={3}
            path="/generate-invoice"
            exact
          />
           <PrivateRoute
            component={ViewInvoices}
            permission={3}
            path="/view-invoices"
            exact
          />
             <PrivateRoute
            component={ViewEstimate}
            permission={3}
            path="/view-estimate"
            exact
          />
             <PrivateRoute
            component={ViewEstimates}
            permission={3}
            path="/view-estimates"
            exact
          />
          
           <PrivateRoute
            component={Transactions}
            permission={5}
            path="/transactions"
            exact
          />
          
           <PrivateRoute
            component={ViewInvoice}
            permission={4}
            path="/view-invoice"
            exact
          />
          <PublicRoute
            component={Login}
            restricted={true}
            path="/login"
            exact
          />
        </Switch>
      </Router>
    </div>
  );
}
export default App;
