import React, { forwardRef, useState, useEffect } from "react";

import {
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider, putCustomer, getProfile, getPDF, isIOSDevice, getPDF_, config} from "../util";
import Toast from "../components/Toast";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SelectAll from "@material-ui/icons/SelectAll";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Refresh, BuildOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    paddingTop: 60,
    // padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function ViewCustomers() {
  const classes = useStyles();
  // {
  //   contact: "",
  //   created_at: "",
  //   email: "",
  //   first_name: "",
  //   id: "",
  //   last_name: "",
  //   mileage: "",
  //   registration: "",
  //   service: "[]",
  //   vin: "",
  // }
  const [data, setData] = useState([]);

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  // -----------------------------------------------------

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    SelectAll: forwardRef((props, ref) => <SelectAll {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => (
      <Refresh
        // style={{ padding: 3, color: "white", backgroundColor: "green" }}
        {...props}
        ref={ref}
      />
    )),
  };

//   useEffect(() => {
//       console.log('e')
//     loadData();
//   }, []);

  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  useEffect(() => {
    loadProfile();
    loadData();
  }, []);
 
  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }


  async function loadData() {
    try {
      const mydata = await dataProvider('get', '/customers', []);
       setData(mydata.data);
    } catch (error) {
      console.log(error)
      setToastMessage(error.message);
      setIsToastOpen(true);
    }

  }

//   const ref = React.useRef(0);
//     React.useEffect(() => {
//         ref.current += 1;
//     });

//   const vin_ref = useRef(0);
  function copyElementText(text) {
    // var text = document.getElementById(id).innerText;
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);

    setToastMessage(text+" copied");
    setIsToastOpen(true);
  }

  const view_customer = [
    { title: "ID", field: "id", editable: "never" },
    {
      title: "First Name",
      field: "first_name",
    },
    { title: "Last Name", field: "last_name" },

    { title: "VIN", field: "vin",
    render: (rowData) => (
      <div onClick={()=> {copyElementText(rowData.vin);}}>
        {rowData.vin}{" "}
      </div>
    ) }
  ];
  const view_admin = [
    { title: "ID", field: "id", editable: "never" },
    {
      title: "First Name",
      field: "first_name",
    },
    { title: "Last Name", field: "last_name" },
    { title: "Registration", field: "registration" },
    { title: "VIN", field: "vin",
    render: (rowData) => (
      <div onClick={()=> {copyElementText(rowData.vin);}}>
        {rowData.vin}{" "}
      </div>
    ) },
    {
      title: "Email",
      field: "email",
      render: (rowData) => (
        <a target="new_"  style={{ color: "#047bea" }} href={"mailto:" + rowData.email}>
          {rowData.email}{" "}
        </a>
      ),
    },
    {
      title: "Contact",
      field: "contact",
      type: "numeric",
      render: (rowData) => (
        <a target="new_" style={{ color: "#047bea" }} href={"tel:" + rowData.contact}>
          {rowData.contact}{" "}
        </a>
      ),
    },
    { title: "Entry Date", field: "created_at", editable: "never" },
    { title: "Points", field: "current_points", editable: "never" },
  ];


  return (
    <div className={classes.root}>
      {/* <form className={classes.formRoot} onSubmit={handleSubmit}> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            style={{ fontSize: 14 }}
            title="Customers"
            
            columns={
                profile?.permission > 2?view_admin:view_customer
            }
                
                       
            
            icons={tableIcons}
            data={data ? data : null}
            
            editable={
                profile?.permission > 2? {
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  putCustomer(newData)
                    .then((res) => {
                      setToastMessage(res.data.message);
                      setIsToastOpen(true);
                      loadData();
                      resolve();
                    })
                    .catch((error) => {
                    setToastMessage(error.message);
                      setIsToastOpen(true);
                      reject();
                    });
                }),
            }:null}
      
            options={{
                pageSize:10,
                pageSizeOptions:[10,20,50,100,500],
              sorting: true,
              search: true,
              exportButton: true,
              rowStyle: (rowData) => {
                // console.log(rowData)
                if (rowData.tableData.id % 2 === 0) {
                  // return { backgroundColor: "#fceffc" };
                  return { backgroundColor: "#eaf9f7" };
                }
              },
            }}
            actions={[
              {
                tooltip: "Refresh",
                icon: tableIcons.Refresh,
                position: "toolbar",
                onClick: () => {
                  loadData();
                },
                // onClick: (evt, data) => {(doAll)?setOpenD(true):setShowSnack(true)}
                // onClick: (evt, data) => alert('Confirm delivery for ' + data.length + ' packages')
              },

              (rowData) =>
                profile?.permission > 3
                  ? {
                      icon: BuildOutlined,
                      tooltip: "Service History",
                      onClick: (event, rowData) => {

                        if(isIOSDevice()) {
                           getPDF(config.domain + "service-history/" + rowData.vin);
                           return;
                        }                      
                        
                        getPDF_(config.domain + "service-history/" + rowData.vin);
                        // setRowData(rowData);
                        // setDialogOpen(true);
                      },
                    }
                  : null,

            ]}
          />
        </Grid>
      </Grid>
      {/* </form> */}
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      />
    </div>
  );
}
