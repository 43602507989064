import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import MaskText from "../components/MaskText";
import Toast from "../components/Toast";
import Toasty from "../components/Toasty";
import BookCalendar from "../components/BookCalendar";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
} from "@material-ui/core";
import service_icon from "../car-service.png";
import customer_icon from "../profile.png";
import { dataProvider, getServiceList, getCustomer, getProfile, doRequest } from "../util";
import AlertDialog from "../components/AlertDialog";
import { Search } from "@material-ui/icons";
// import * as Scroll from 'react-scroll';
import { scroller } from "react-scroll";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
// var scroll = animateScroll;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  input: {
    color: "blue",
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function ServiceRequest() {
  let history = useHistory();
  const classes = useStyles();
  const [service, setService] = React.useState({});
  const [customer, setCustomer] = React.useState({});
  const [bookData, setBookData] = React.useState([]);

  const [serviceList, setServiceList] = React.useState([]);
  // const [agree, setAgree] = React.useState(false);
  const form = useRef();

  //AlertDialog ----------------------------------------
  const [isOpen, setIsOpen] = React.useState(false);
  const handleDialogOpen = () => {

   
   setIsOpen(true);

    // } catch (error) {
    //   console.log(error);
    //   doToasty(error.message);
    //   // setIsToastOpen(true);
    // }

    //calculate mileage recommendations
    // if (customer["Mileage"]?.length > 0) {
    //   let warning = 'You may need the following services: ';

    //   if(customer["Mileage"] >= 170000){
    //     warning += "(Timing Chain) ";
    //   }
    //   if(customer["Mileage"] >= 160000){
    //     warning += "(Main Belt) ";
    //   }

    //   if(customer["Mileage"] >= 160000){
    //     warning += "(Transmission) ";
    //   }

    //   if(customer["Mileage"] >= 96000){
    //     warning += "(Spark Plugs) ";
    //   }

    //   if(customer["Mileage"] >= 24000){
    //     warning += "(Cabin Filter) ";
    //   }

    //   if(customer["Mileage"] >= 72000){
    //     warning += "(Air Filter) ";
    //   }

    //   if(warning !== "You may need the following services: ") doToasty(warning);
      
    // }
    
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -90,
    });
  };
  const handleDialogAgree = () => {
    //  window.alert('agree');
    // console.log('agree')
    setIsOpen(false);
    // setAgree(true);

    if (
      !customer["Registration Number"] ||
      customer["Registration Number"].length < 2
    ) {
      doToast("Invalid Registration Number");
      scrolly("Registration Number");
      return false;
    }

    // if(!customer) return false;

    if (!customer["VIN"] || customer["VIN"].length < 1) {
      doToast("Invalid VIN");
      scrolly("VIN");
      return false;
    }

    if (!customer["Mileage"] || customer["Mileage"].length < 1) {
      doToast("Invalid Mileage");
      scrolly("Mileage");
      return false;
    }
    if (!customer["First name"] || customer["First name"].length < 1) {
      doToast("Name should be at least 1 character");
      scrolly("First name");
      return false;
    }
    if (!customer["Last name"] || customer["Last name"].length < 1) {
      doToast("Name should be at least 1 character");
      scrolly("Last name");
      return false;
    }
    // if (!customer["Email"] || customer["Email"].length < 1) {
    //   doToast("Email should be atleast 1 character");
    //   scrolly("Email");
    //   return false;
    // }
    if (!customer["Contact number"] || customer["Contact number"].length < 10) {
      doToast(
        "Contact number should be at least (10) digits. e.g 8761234567 or 18765001234"
      );
      scrolly("Contact number");
      return false;
    }
    if (
      typeof customer["Email"] !== "undefined" &&
      customer["Email"] !== "none"
    ) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(customer["Email"])) {
        doToast("Please enter valid email address.");
        return false;
      }
    }

    Object.entries(service).forEach((v, i, a) => {
      // console.log(v);
      if (v[1] === false) {
        delete service[v[0]];
      }
    });

    if (Object.keys(service).length < 1) {
      doToast("Select at least one(1) service");
      scrolly("request_service");
      return false;
    }

    //save form
    let save_service = [];
    Object.entries(service).forEach((v, i, a) => {
      // console.log(v);
      if (v[1] === true) {
        save_service.push(v[0]);
      }
    });

    //   doRequest("POST", "/service", {
    //     customer: customer,
    //     service: save_service,
    //   }).then((res) => {
    //   if(!res) return false;
    //   const {data} = res;
    //   reset(); //
    //   Swal.fire({
    //     text:data?.message,
    //     icon:'info'
    //   }).then((d) =>{
    //     return setTimeout(() => {
    //       reset(); //
    //       history.push("/make-request");
    //       scrolly("pageTop");
    //     }, 1200);

    //   })
    // })
    // return;

    dataProvider("post", "/service", {
      customer: customer,
      service: save_service,
    })
      .then((res) => {
        setIsOpen(false);
        setToastMessage(res.data.message);
        setIsToastOpen(true);
        return setTimeout(() => {
          reset(); //
          history.push("/make-request");
          scrolly("pageTop");
        }, 2000);
      })
      .catch((error) => {
        setIsOpen(false);
        setToastMessage(error.message);
        setIsToastOpen(true);
        //  window.location = "/login";
      });
  };

  // const baseCustomer = {
  //   "Registration Number": "",
  //   VIN: "",
  //   Mileage: "",
  //   "First name": "",
  //   "Last name": "",
  //   Email: "",
  //   "Contact number": "",
  // };

  const reset = () => {
    // console.log(customer);
    // console.log(service);
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
    setService({});
    setCustomer({});
    setBookSwitch(false);
  };

  const resetCustomer = () => {
    // console.log(customer);
    // console.log(service);
    Array.from(document.querySelectorAll("input")).forEach((input) => {
      if (input.id !== "vin") input.value = "";
    });
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
    setCustomer({});
  };

  const doToasty = (message) => {
    setToastMessage(message);
    setIsToastyOpen(true);
  };
  const doToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };
  const handleDialogCancel = () => {
    // window.alert('cancel');
    setIsOpen(false);
  };
  // -----------------------------------------------------

  //Toast ----------------------------------------
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [isToastyOpen, setIsToastyOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  // const handleToastOpen = () => {
  //   setIsToastOpen(true);
  // };
  const handleToastClose = () => {
    setIsToastOpen(false);
  };
  const handleToastyClose = () => {
    setIsToastyOpen(false);
  };
  // -----------------------------------------------------

  const handleChange = (event) => {
    // if(event.target.checked === true) {
    setService({ ...service, [event.target.name]: event.target.checked });
    // }
  };

  const handleCustomerChange = (event) => {
    setCustomer({ ...customer, [event.target.name]: event.target.value });
  };
  const handleCustomerUpdate = (obj) => {
    setCustomer({ ...customer, ...obj});
  };
  const [profile, setProfile] = React.useState({ username: "", permission: 0 });
  async function loadProfile() {
    const profile = await getProfile();
    setProfile(profile);
  }


  useEffect(() => {
    async function fetchServices() {
       try {
          let live_data = await getServiceList();
          if (typeof live_data === "undefined" || live_data === "Network Error")
            throw new Error("Please restart application. Network Error.");
          setServiceList(live_data.data);
          scrolly("pageTop");
        } catch (error) {
          setToastMessage(error.message);
          setIsToastOpen(true);
        }

    }
    loadProfile();
    fetchServices();

    // if(profile.permission > 3) {
      fetchBooked();
    // }
 
   
  }, []);

  const fetchBooked = async() =>{
    try {
      const mydata = await dataProvider('post', '/bookings-fetch', {status:"booked"});
      if(mydata.status !== 200){
    
        setToastMessage(mydata?.message);
        setIsToastOpen(true);
        return false;
      }

      setBookData(mydata.data);
    } catch (error) {
      console.log(error)
      setToastMessage(error.message);
      setIsToastOpen(true);
    }
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function suggestServices(){
    dataProvider("post", "/outstanding-services", {mileage:customer["Mileage"], vin:customer["VIN"]}).then( (mydata) =>{
      // console.log(mydata.data)
      if(mydata.data.value) {
        doToasty(mydata.data.message);
      
        let d = mydata.data.data;
       
        mydata.data.data.forEach((v ,i, a) => {
          // console.log(v, "ITEMS")
          setService((prev) => ({ ...prev, [v.title]: true}));
          // setService(d)
        })      
        // setIsToastOpen(true);
      }
     }).catch(err => {
       console.log(err)
     })
  }
  const populateCustomer = async (qvin) => {

    let vin = null;

    if (customer && customer["VIN"]?.length > 2) vin = customer["VIN"];
    if (qvin && qvin?.length > 2) vin = qvin;
    if (vin?.length < 3) return false;

    try {
      const data = await getCustomer(vin);
      let mycust = data.data[0];
      setCustomer(mycust);
      // console.log(mycust, "setcust");

      if (typeof mycust === "undefined") {
        setToastMessage("Customer NOT FOUND.");
        setIsToastOpen(true);
        resetCustomer();
        return false;
      }
      // console.log(data.data[0])
      if (mycust["First name"]) {
        // setCustomer(data.data[0]);

        Array.from(document.querySelectorAll("input")).forEach((input) => {
          if (input.id) {
            input.value = mycust[input.name];
            // console.log(input.name);
          }
        });

      }
    } catch (error) {
      // console.log(error);
      setToastMessage(error.message);
      setIsToastOpen(true);
    }

    //
  };

  const queryVin = useQuery().get("vin");
  useEffect(() => {
    if (queryVin?.length > 2) populateCustomer(queryVin);
  }, [queryVin]);

  // useEffect(() => {
  //  console.log(service, "service-state");
  // }, [service]);

  const [bookSwitch, setBookSwitch] = useState(false);

  // const calendarData
  
  const selectBookDate = async (d) => {
    // return {start, end, title}
    // console.log(d,"ds");
    // console.log(d,"ds");

    if(d.book_start && d.book_end && (moment(d.book_start).unix() > moment(d.book_end).unix()) ){
      setToastMessage("Invalid booking dates");
      setIsToastOpen(true);
      return false;
    }

    if(d.book_start === "Invalid date" || d.book_end === "Invalid date" ) return false;
    setCustomer({ ...customer, book_start: d.book_start, book_end: d.book_end });
    return true;
  //  console.log(d.book_start)
  //  console.log(d.book_end)
    // let book_start = moment(start).format("YYYY-MM-DD HH:MM");
    // let book_end = moment(end).add(1, "hours").format("YYYY-MM-DD HH:MM");
    // console.log(bookstart+" - "+bookend);
    // handleCustomerUpdate({"book_start": book_start, "book_end": book_end})
    // handleCustomerUpdate({name:"book_end", value:book_end})
// //  return false;
//     const title = window.prompt('New Event name')
//     if (title)
//       setState({
//         events: [
//           ...state.events,
//           {
//             start,
//             end,
//             title,
//           },
//         ],
//       })
}

  return (
    <div className={classes.root} id="pageTop">
      <form className={classes.formRoot} autoComplete="off" ref={form}>
        <Grid container spacing={3} style={{ paddingTop: 30 }}>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="h2"
                    style={{ fontSize: "2em" }}
                  >
                    Welcome to <b>Bimmer Lifestyle Autocare</b>
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  marginTop: 15,
                  background: "#f1f1f1",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <ServiceIcon fontSize="large" className={classes.icon}/> */}
                  <img alt="customer" src={customer_icon} width={60} />
                  <Typography gutterBottom variant="h5">
                    Customer details
                  </Typography>
                </Grid>
                {/* <Grid item>
            <Typography gutterBottom variant="h6">
              $4.50
            </Typography>
          </Grid> */}
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Please provide us with a few details, so we can serve your
                better.
              </Typography>
              {/* <Divider style={{ marginTop: 15 }} /> */}
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textSecondary" variant="caption">
              Please enter VIN and press search to continue.
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <TextField
              label="VIN"
              name="VIN"
              id="vin"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              InputLabelProps={{
                shrink: true,
              }}
              // helperText="Incorrect entry."
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
            <IconButton
              color="primary"
              className={classes.button}
              aria-label="Get customer details"
              onClick={populateCustomer}
            >
              <Search />
            </IconButton>
            <small style={{ color: "#ccc" }}>Vehicle ID Number</small>
          </Grid>
          <Grid item xs={6} md={6}>
            {/* <Paper className={classes.paper}>xs=12</Paper> */}
            <TextField
              label="Registration Number"
              variant="outlined"
              className={classes.flow}
              name="Registration Number"
              onChange={handleCustomerChange}
              required
              disabled
              id="registration"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
                className: classes.input,
              }}
            />
            <small style={{ color: "#ccc" }}>
              Licence plate number e.g 3456AB
            </small>
          </Grid>

          <Grid item xs={6} md={6}>
            {/* <Paper className={classes.paper}>xs=12</Paper> */}
            <TextField
              type="number"
              label="Mileage"
              name="Mileage"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              onBlur={suggestServices}
              required
              // helperText="Incorrect entry."
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="First name"
              name="First name"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              disabled
              id="first_name"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Last name"
              name="Last name"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              disabled
              id="last_name"
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
                className: classes.input,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Contact number"
              name="Contact number"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              required
              disabled
              id="contact"
              // InputProps={{
              //   inputComponent: MaskText,
              // }}
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 11);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 11, className: classes.input }}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              // defaultValue=""
              label="Email"
              name="Email"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              type="email"
              id="email"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
                className: classes.input,
              }}
              // required
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Note"
              name="Note"
              variant="outlined"
              className={classes.flow}
              onChange={handleCustomerChange}
              rows={3}
              autoComplete="off"
              inputProps={{
                autoComplete: "off",
                form: {
                  autoComplete: "off",
                },
                maxLength: 500,
              }}
              multiline
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <img
                    alt="services"
                    src={service_icon}
                    width={70}
                    id="request_service"
                  />
                  <Typography gutterBottom variant="h5">
                    I want the following services
                  </Typography>
                </Grid>
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Only services selected will be done.
              </Typography>
              {/* <Divider style={{ marginTop: 15 }} /> */}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {serviceList?.map((data, index, arr) => {
                // setService({ ...service, [data.description]: false});

                return (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ textAlign: "left", border: "0px solid #f3f3f3" }}
                    >
                      <FormControlLabel
                        required
                        key={data.title}
                        control={
                          <Checkbox
                            key={data.title}
                            checked={
                              service[data.description]
                                ? service[data.description]
                                : false
                            }
                            onChange={handleChange}
                            name={data.description}
                          />
                        }
                        label={
                          data.description[0] !==
                          arr[index - 1]?.description[0] ? (
                            <span>
                              <b
                                style={{
                                  color: "#000",
                                  fontSize: "1.2em",
                                  textDecoration: "underline",
                                }}
                              >
                                {data.description[0]}
                              </b>
                              {data.description.substring(1)}
                            </span>
                          ) : (
                            data.description
                          )
                        }
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: 40,
              display: profile.permission > 3 ? "none" : "block",
            }}
          >
            <Typography variant="h5">
              <u style={{ textTransform: "uppercase" }}>
                Authorization for Repairs
              </u>
            </Typography>

            <Typography
              variant="subtitle1"
              style={{ marginTop: 15, textAlign: "left" }}
            >
              <b>Scott's Auto Electrical Supplies Service Center</b> reserves
              the rights to assess storage charge, if I <b>FAIL</b> to;
              <ol>
                <li>Supply parts within five(5) days after notification.</li>
                <li>
                  Collect my vehicle witin seven(7) days after work completion.
                </li>
              </ol>
              I will incurr a charge of $2,000 JMD for the first day and $1,000
              each day onward, if I fail to comply with the terms (1 and 2)
              listed above.
            </Typography>
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Typography
              variant="subtitle1"
              style={{
                marginTop: 15,
                textAlign: "left",
                textTransform: "uppercase",
              }}
            >
              I hereby authorize the repair work herein set forth to be done
              along with the necessary material. I hereby grant{" "}
              <b>Scott's Auto Electrical Supplies Service Center</b> AND/OR your
              employees permission to operate the vehicle herein described, on
              streets, highways or elsewhere, for the purpose of testing AND/OR
              inspection.{" "}
              <b>
                I hereby acknowledge that I was notified and gave oral approval
                of the above Terms &amp; Conditions.
              </b>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: 40,
              display: profile.permission > 3 ? "block" : "none",
            }}
          >
            <div className={classes.section1}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {/* <img
                    alt="services"
                    src={service_icon}
                    width={70}
                    id="request_service"
                  /> */}
                  <Typography gutterBottom variant="h5">
                    Show calendar? {" "}
                    <Switch
                      checked={bookSwitch}
                      onChange={() => {
                        setBookSwitch(!bookSwitch);
                      }}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Typography color="textSecondary" variant="body2">
                Book customer for a future date.
              </Typography>
            </div>
            <Divider style={{ marginTop: 15 }} />
            <div>
              {bookSwitch ? <div><BookCalendar dateSelect={selectBookDate} />
              
              <input type="hidden" name="book_start" onChange={handleCustomerChange} />
              <input type="hidden" name="book_end"  onChange={handleCustomerChange} />
              </div> : ""}
            
            </div>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              size="large"
              onClick={reset}
              style={{ marginRight: 20 }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleDialogOpen}
              // type="submit"
            >
              Submit
            </Button>

            <AlertDialog
              isOpen={isOpen}
              handleClose={handleDialogClose}
              handleAgree={handleDialogAgree}
              handleCancel={handleDialogCancel}
              title="Scott's Auto"
              subtitle="By clicking AGREE, means you have read our Terms and Conditions with full understanding and agreement. Thanks for making it Scott's Auto."
              // children={<b>OK</b>}
            />
          </Grid>
        </Grid>
      </form>
      <Toast
        isOpen={isToastOpen}
        handleClose={handleToastClose}
        message={toastMessage}
      /> <Toasty
        isOpen={isToastyOpen}
        handleClose={handleToastyClose}
        message={toastMessage}
      />
      <Modal>
        <div>hey</div>
      </Modal>
    </div>
  );
}
